:root {
  --color1: #2f4858;
  --color2: #007d6d;
  --color3: #00626f;
  --color4: #8fa132;
  --color5: rgb(223, 224, 174);

  --color6: rgb(255, 255, 255);

  --css-fill: #000;

  /* Component css vars */
  --footer-theme: var(--color1);

  --button-theme: var(--color5);
  --button-primary: var(--color2);
  --button-secondary: var(--color6);
  --button-primary-hover: var(--color6);
  --button-secondary-hover: var(--color6);

  path {
    --css-fill: #333;
  }
}

path {
  fill: var(--css-fill);
}
